import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProjectService {
    readProjectQuery(){
        var query = `query($paging:ServerPaging, $projecttype:Int, $status:[String]){
            GetTransactionProject(Paging:$paging, ProjectType:$projecttype, Status:$status){
                project{
                    project_id
                    status
                    project_code
                    project_name
                    project_date
                    city
                    notes
                    project_duration
                    sales_name
                    price_category_name
                    pre_project_code
                    project_detail
                }
                total
            }
        }`;
        return query;
    }

    async getTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project"]){
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;

        var typeDetailData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { type_detail_id : data[i].type_detail_id,
                        type_detail_name : data[i].type_detail_name,
                        discount1 : 0,
                        discount2 : 0,
                        discount3 : 0,
                        discount4 : 0,
                        discount5 : 0,
                        is_shown : false}
            typeDetailData.push(str);
        }
        return typeDetailData
    }

    async getProjectDetailQuery(id){
        const variables = {
            id : id
        }
        var query = gql`query($id:Int){
            GetTransactionProject(ProjectId:$id){
                project {
                    created_at
                    last_update
                    project_id
                    project_code
                    project_type
                    project_date
                    project_duration
                    project_name
                    status
                    pre_project_id
                    pre_project_code
                    pre_project_name
                    forecast_type
                    storage_id
                    storage_name
                    price_category_id
                    price_category_name
                    areacode
                    areaname
                    sales_id
                    sales_name
                    city
                    attachment
                    notes
                    project_detail
                    project_affiliate {
                        created_at
                        last_update
                        project_id
                        affiliate_id
                        affiliate_code
                        affiliate_name
                        commision
                    }
                    project_distributor {
                        created_at
                        last_update
                        project_id
                        distributor_id
                        distributor_code
                        distributor_name
                    }
                    project_consultant {
                        created_at
                        last_update
                        project_id
                        consultant_id
                        consultant_code
                        consultant_name
                    }
                    project_sales {
                        created_at
                        last_update
                        project_id
                        sales_id
                        sales_code
                        sales_name
                        position
                    }
                    project_discount {
                        created_at
                        last_update
                        project_id
                        type_detail_id
                        type_detail_name
                        discount1
                        discount2
                        discount3
                        discount4
                        discount5
                        discount_cash
                        is_shown
                    }
                    project_etc {
                        created_at
                        last_update
                        project_id
                        contact_id
                        contact_code
                        contact_name
                        position
                    }
                    project_item_forcast {
                        created_at
                        last_update
                        project_id
                        purchase_order_sequence
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_weight
                        base_uom
                        uom
                        conv_amount
                        base_quantity
                        quantity
                        price_project
                        price_retail
                        base_price_project
                        base_price_retail
                        price_project_discount
                        price_retail_discount
                        base_price_project_discount
                        base_price_retail_discount
                        price_project_discount_cash
                        price_retail_discount_cash
                        base_price_project_discount_cash
                        base_price_retail_discount_cash
                    }
                    on_going_purchase_order_total_by_project_id
                }              
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionProject.project[0];
    }  

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetAreaCode;
        var areaData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].areacode, 
                            label: data[i].areacode + ' (' + data[i].areaname + ')'}
                areaData.push(str);
            }
        }
        return areaData
    }

    async getPriceTypeQuery(){
        var query = gql`query{
            GetPriceCategory{
                ItemPriceCategory{
                    price_category_id
                    price_category_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetPriceCategory;
        var priceData = [];
        
        if(data != null){
            for (let i = 0; i < data.ItemPriceCategory.length; i++) {
                var str = { value: data.ItemPriceCategory[i].price_category_id, 
                            label: data.ItemPriceCategory[i].price_category_name}
                priceData.push(str);
            }
        }
        
        return priceData
    }

    async readTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail {
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;
        var typeDetailData = [];

        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    value: data[i].type_detail_id, 
                    label: data[i].type_detail_id + ' (' + data[i].type_detail_name + ')'
                }
                typeDetailData.push(str);
            }
        }
        return typeDetailData
    }

    readContactQuery(){
        var query = `query($type:String,$status:String){
                        GetContact(ContactType:$type, ActiveFlag:$status){
                            contact_id
                            contact_code
                            contact_name
                            metadata
                        }
                    }`;
        return query;
    }

    readItemQuery(){
        var query = `query($priceCategoryId:String!,$storageId:String!,$typeDetailId:String){
                        GetItemForcastV2(   ActiveFlag:"A", 
                                            PriceCategoryId:$priceCategoryId, 
                                            StorageId:$storageId,
                                            TypeDetailId:$typeDetailId,
                                            Type:"Project"){
                            item_id
                            product_code
                            item_name
                            active_flag
                            type_detail_id
                            type_detail_name
                            group_type_id
                            item_group_name
                            base_uom
                            price_category_id
                            storage_id
                            ItemForcastDetail{
                                item_id
                                product_code
                                item_name
                                active_flag
                                type_detail_id
                                type_detail_name
                                group_type_id
                                item_group_name
                                base_uom
                                uom_conv
                                conv_amount
                                storage_id
                                price_category_id
                                price_retail
                                price_project
                                stock
                            }
                        }
                    }`;
        return query;
    }

    gridSelector(selected, checkall, data){
        if (checkall == 'true'){
            if(selected == ''){
                var newData = data;
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.contact_id]= el
                })
                for (let i = 0; i < selectedData.length; i++) {
                    delete hashMap[selectedData[i].toString()]
                }
                var newData = [];
                Object.keys(hashMap).forEach(el=>{
                    newData.push(hashMap[el])
                })
                return newData;
            }
        }else{
            if(selected == ''){
                var newData = [];
                return newData;
            }else{
                var selectedData =  selected.split(",");
                var hashMap = [];
                data.forEach(el=>{
                    hashMap[el.contact_id]= el
                })
                var newData = [];
                for (let i = 0; i < selectedData.length; i++) {
                    newData.push(hashMap[selectedData[i].toString()])
                }
                return newData;
            }
        }
    }

    async getKacabQuery(){
        var query = gql`query{
            GetContact(ContactType:"Employee"){
            contact_id
              contact_name
          }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetContact;
        var kacabData = [];
        for (let i = 0; i < data.length; i++) {
            var label = data[i].contact_name
            var str = { value: data[i].contact_id, 
                        label: label}
            kacabData.push(str);
        }
        return kacabData;
    }

    async getSalesmanQuery(kacab){
        if(kacab == null){
            return [];
        }
        const variables = {
            id : kacab.value
        }
        var query = gql`query($id:Int){
            GetContact(ContactType:"Employee", Kacab:$id){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query,variables);
        var data = result.data.GetContact;
        var salesmanData = [];
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].contact_id, 
                            label: data[i].contact_name}
                salesmanData.push(str);
            }
        }else{
            salesmanData.push(kacab);
        }
        return salesmanData;
    }  

    async getStorageQuery(){
        var query = gql`query{
            GetStorage{
            storage_id
                storage_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetStorage;
        var storageData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { value: data[i].storage_id, 
                        label: data[i].storage_name}
            storageData.push(str);
        }

        return storageData;
    }

    async getPreProjectQuery(){
        var query = gql`query{
            GetTransactionPreProject(Status:["Approved","Komisi"]){
                pre_project{
                    pre_project_id
                    pre_project_code
                    pre_project_date
                    pre_project_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTransactionPreProject.pre_project;
        var preProjectData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { value:data[i].pre_project_id, 
                        label:data[i].pre_project_code + ' (' + data[i].pre_project_name + ')'}
            preProjectData.push(str);
        }
        return preProjectData;
    }

    forecastQtyValidator(data){
        var checker = 0;
        var itemQty = 0;
        for (let i = 0; i < data.length; i++){
            if(data[i].quantity != undefined){
                checker++;
                itemQty = data[i];
            }
        }
        
        var result;
        if(checker == 0){
            result = "error_empty";
        }else if(checker == 1){
            result = itemQty;
        }else if(checker > 1){
            result = "error_over_data";
        }

        return result;
    }

    duplicateForecastChecker(oldData, newData){
        var duplicate = 0;
        for (let i = 0; i < oldData.length; i++){
            if(oldData[i].po == newData.po && oldData[i].product_code == newData.product_code ){
                duplicate++;
            }
        }
        return duplicate==0 ? false : true;
    }

    async addQuery(variables){
        var query = gql`mutation ($data:NewTransactionProject!) {
            CreateTransactionProject (New:$data)
        }`;
        
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation ($id:Int!, $data:NewTransactionProject!) {
            UpdateTransactionProject (ProjectID:$id, New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation( $id: Int! ){
            DeleteTransactionProject( ProjectID: $id )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getLimitRemaining(id, projectType){
        if(projectType == 'None'){
            var variables = {id : id};
            var query = gql`query($id:Int){
                GetTransactionPurchaseOrder(ProjectID:$id){
                    transaction_purchase_order{
                        on_going_purchase_order_total_by_project_id
                    }
                }
            }`;
            var result = await globalfunc.defaultApolloQueryDefault(query, variables);
            var data = result.data.GetTransactionPurchaseOrder.transaction_purchase_order;
            
            var total = 0;
            if(data.length != 0){
                total = data[0].on_going_purchase_order_total_by_project_id;
            }
            
            return total;
        }else{
            return 0;
        }
    }

    countBaseDiscountForecast(forecastData, typeDetailData){
        var hashMap = [];
        typeDetailData.forEach(el=>{
            hashMap[el.type_detail_id]= el
        });
        var discountData = hashMap[forecastData.type_detail_id];
        
        var discountAmount1 = Math.round(forecastData.base_price_project * (discountData.discount1 / 100));
        var discountAmount2 = Math.round((forecastData.base_price_project - discountAmount1) * (discountData.discount2 / 100));
        var discountAmount3 = Math.round((forecastData.base_price_project - discountAmount1 - discountAmount2) * (discountData.discount3 / 100));
        var discountAmount4 = Math.round((forecastData.base_price_project - discountAmount1 - discountAmount2 - discountAmount3) * (discountData.discount4 / 100));
        var discountAmount5 = Math.round((forecastData.base_price_project - discountAmount1 - discountAmount2 - discountAmount3 - discountAmount4) * (discountData.discount5 / 100));
        var total_discount = discountAmount1 + discountAmount2 + discountAmount3 + discountAmount4 + discountAmount5;

        return forecastData.base_price_project - total_discount;
    }

    countBaseDiscountCashForecast(discountCash, basePriceProjectDiscount){
        return basePriceProjectDiscount - Math.round(basePriceProjectDiscount * (discountCash / 100));
    }

    calculateForecastData(forecastData, typeDetailData, discountCash){
        var basePriceProjectDiscount = this.countBaseDiscountForecast(forecastData, typeDetailData);
        var basePriceProjectDiscountCash = this.countBaseDiscountCashForecast(parseFloat(discountCash), basePriceProjectDiscount);

        var str = { 
                po: forecastData.po, 
                item_id: forecastData.item_id,
                product_code: forecastData.product_code, 
                item_name: forecastData.item_name,
                type_detail_id: forecastData.type_detail_id,
                base_uom: forecastData.base_uom,
                uom_conv: forecastData.uom_conv,
                conv_amount: forecastData.conv_amount,
                quantity: forecastData.quantity,
                //price
                base_price_project: forecastData.base_price_project,
                price_project: forecastData.price_project,
                total_price_project: forecastData.quantity * forecastData.price_project,
                //price discount
                base_price_project_discount: basePriceProjectDiscount,
                price_project_discount: basePriceProjectDiscount * forecastData.conv_amount,
                total_price_project_discount: basePriceProjectDiscount * forecastData.conv_amount * forecastData.quantity,
                //price discount cash
                base_price_project_discount_cash: basePriceProjectDiscountCash,
                price_project_discount_cash: basePriceProjectDiscountCash * forecastData.conv_amount,
                total_price_project_discount_cash: basePriceProjectDiscountCash * forecastData.conv_amount * forecastData.quantity,
            };

        return str;
    }

    countTotal(data){
        var result = 0;
        if(data.length > 0){
            for (let i = 0; i < data.length; i++) {
                result = result + data[i].total_price_project_discount_cash 
            }
        }
        return result
    }
}

export default new ProjectService();